import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { CircularProgress, Typography, Box, ThemeProvider, createTheme } from '@mui/material';

//const serverURL = "";
const serverURL = "https://ov-research-5.uwaterloo.ca";

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#ff752b",
    },
    secondary: {
      main: "#277c5c",
    },
  },
});

const VideoPlayer = () => {
  // State for video queue management
  const [videoQueue, setVideoQueue] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState({
    video1: 'pending',
    video2: 'pending',
    video3: 'pending',
    video4: 'pending'
  });

  // Existing states
  const [videos, setVideos] = useState({ current: null, next: null });
  const [isCrossFading, setIsCrossFading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasRequestedNext, setHasRequestedNext] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const [userID, setUserID] = useState(null);
  const [isFirstVideoReady, setIsFirstVideoReady] = useState(false);

  const navigate = useNavigate();
  const videoA = useRef(null);
  const videoB = useRef(null);
  const [isAActive, setIsAActive] = useState(true);

  const firebase = useContext(FirebaseContext);

  const CROSSFADE_DURATION = 8;
  const SAFETY_MARGIN = 0.1;
  const MAX_PLAYED_VIDEOS = 8;
  const QUEUE_SIZE = 4;

  const getCurrentVideoRef = () => isAActive ? videoA : videoB;
  const getNextVideoRef = () => isAActive ? videoB : videoA;

  const queueSizeRef = useRef(0);
  const isRequestInProgress = useRef(false);
  const recentVideosRef = useRef([]);

  const isVideoInQueue = (videoPath, queue) => {
    if (!videoPath || !queue) return false;
    return queue.some(queuedPath =>
      queuedPath.split('/').pop() === videoPath.split('/').pop()
    );
  };

  useEffect(() => {
    //console.log('[Auth] Starting authentication initialization');

    if (!firebase) {
      console.error('[Auth] Firebase not initialized');
      navigate('/login');
      return;
    }

    //console.log('[Auth] Firebase available, setting up auth state listener');

    const unsubscribe = onAuthStateChanged(firebase.auth, async (user) => {
      //console.log('[Auth] Auth state changed:', user ? 'User logged in' : 'No user');

      if (user) {
        try {
          //console.log('[Auth] Getting ID token for user:', user.uid);
          const token = await user.getIdToken();
          //console.log('[Auth] Successfully got ID token');
          setIdToken(token);
          setUserID(user.uid);
        } catch (error) {
          console.error('[Auth] Error getting token:', error);
          navigate('/login');
        }
      } else {
        //console.log('[Auth] No authenticated user, redirecting to login');
        setIdToken(null);
        navigate('/login');
      }
    });

    return () => {
      //console.log('[Auth] Cleaning up auth state listener');
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [navigate, firebase]);

  const updateRecentVideos = (video) => {
    // Extract base name for uniqueness check
    const newVideoBase = video.split('/').pop().split('.')[0].split('_segment_')[0];
    
    // Filter out any existing instances of this video before adding the new one
    const filteredHistory = recentVideosRef.current.filter(v => {
      const baseFilename = v.split('/').pop().split('.')[0].split('_segment_')[0];
      return baseFilename !== newVideoBase;
    });
    
    // Add new video to history
    const newHistory = [...filteredHistory, video];
    
    // Keep the most recent MAX_PLAYED_VIDEOS entries
    recentVideosRef.current = newHistory.slice(-MAX_PLAYED_VIDEOS);
    
    //console.log('[History] Updated recent videos:', 
      //recentVideosRef.current.map(v => v.split('/').pop().split('.')[0]));
  };
  
  const getAllExcludedVideos = () => {
    // Get both history and queue videos
    const historyVideos = recentVideosRef.current;
    const queueVideos = videoQueue;
    
    // Process all videos to their base names
    const processVideo = (video) => {
      const filename = video.split('/').pop().split('.')[0];
      return filename.includes('_segment_') 
        ? filename.split('_segment_')[0] 
        : filename;
    };
  
    // Build ordered list of unique videos, keeping most recent occurrence
    const seen = new Set();
    const allVideos = [...historyVideos, ...queueVideos];
    const uniqueVideos = [];
  
    // Process from newest to oldest
    for (let i = allVideos.length - 1; i >= 0; i--) {
      const baseName = processVideo(allVideos[i]);
      if (!seen.has(baseName)) {
        seen.add(baseName);
        uniqueVideos.unshift(baseName);
      }
    }
  
    // Take the most recent MAX_PLAYED_VIDEOS
    const result = uniqueVideos.slice(-MAX_PLAYED_VIDEOS);
  
    //console.log('[History] History base names:', historyVideos.map(processVideo));
    //console.log('[History] Queue base names:', queueVideos.map(processVideo));
    //console.log('[History] Final exclusion list:', result);
    
    return result;
  };

  // Function to preload a video and return a promise
  const preloadVideo = (src) => {
    return new Promise((resolve, reject) => {
      //console.log(`[Preload] Starting to preload video: ${src}`);
      const video = document.createElement('video');
      video.preload = 'auto';

      // Log progress events
      //video.onloadstart = () => console.log(`[Preload] Load started for: ${src}`);
      //video.onprogress = () => console.log(`[Preload] Loading in progress for: ${src}`);
      //video.oncanplay = () => console.log(`[Preload] Can start playing: ${src}`);

      video.onloadeddata = () => {
        //console.log(`[Preload] Successfully loaded data for: ${src}`);
        resolve(src);
      };

      video.onerror = (e) => {
        console.error(`[Preload] Error preloading video: ${src}`, e);
        reject(new Error(`Failed to preload video: ${src}`));
      };

      video.src = src;
    });
  };

  const fetchNextVideo = async (sourceClipName) => {
    if (!idToken) {
      console.error('No auth token available');
      return null;
    }

    try {
      const excludedClips = getAllExcludedVideos();

      const queryParams = new URLSearchParams({
        sourceClipName: sourceClipName || 'null',
        numTopClips: '100',
        excludedClips: JSON.stringify(excludedClips)
      });

      //console.log('Fetching next video with params:', sourceClipName);
      const response = await fetch(`${serverURL}/api/get-next-clip?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch next video: ${response.status}`);
      }

      const data = await response.json();
      //console.log('Received video path:', data.video_path);
      return data.video_path;

    } catch (error) {
      console.error('Error in fetchNextVideo:', error);
      if (error.message.includes('401')) {
        try {
          const user = firebase.auth.currentUser;
          if (user) {
            const newToken = await user.getIdToken(true);
            setIdToken(newToken);
          } else {
            navigate('/login');
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          navigate('/login');
        }
      }
      setError(error.message);
      return null;
    }
  };

  // Initialize video queue
  // Initialize video queue
  useEffect(() => {
    const initializeVideoQueue = async () => {
      if (!idToken) {
        console.log('[Init] No idToken available yet, waiting...');
        return;
      }

      try {
        //console.log('[Init] Starting video queue initialization');
        setIsLoading(true);

        // Fetch and preload first video
        //console.log('[Init] Fetching first video...');
        setLoadingStatus(prev => ({ ...prev, video1: 'loading' }));
        const firstVideoPath = await fetchNextVideo(null);
        if (!firstVideoPath) {
          console.error('[Init] Failed to fetch first video path');
          throw new Error('Failed to fetch first video');
        }
        //console.log('[Init] First video path received:', firstVideoPath);
        await preloadVideo(firstVideoPath);
        //console.log('[Init] First video preloaded successfully');
        setLoadingStatus(prev => ({ ...prev, video1: 'complete' }));

        // Fetch and preload second video
        //console.log('[Init] Fetching second video...');
        setLoadingStatus(prev => ({ ...prev, video2: 'loading' }));
        const secondVideoPath = await fetchNextVideo(firstVideoPath.split('/').pop().split('.')[0]);
        if (!secondVideoPath) {
          console.error('[Init] Failed to fetch second video path');
          throw new Error('Failed to fetch second video');
        }
        //console.log('[Init] Second video path received:', secondVideoPath);
        await preloadVideo(secondVideoPath);
        //console.log('[Init] Second video preloaded successfully');
        setLoadingStatus(prev => ({ ...prev, video2: 'complete' }));

        // Fetch and preload third video
        //console.log('[Init] Fetching third video...');
        setLoadingStatus(prev => ({ ...prev, video3: 'loading' }));
        const thirdVideoPath = await fetchNextVideo(secondVideoPath.split('/').pop().split('.')[0]);
        if (!thirdVideoPath) {
          console.error('[Init] Failed to fetch third video path');
          throw new Error('Failed to fetch third video');
        }
        //console.log('[Init] Third video path received:', thirdVideoPath);
        await preloadVideo(thirdVideoPath);
        //console.log('[Init] Third video preloaded successfully');
        setLoadingStatus(prev => ({ ...prev, video3: 'complete' }));

        // Fetch and preload fourth video
        //console.log('[Init] Fetching fourth video...');
        setLoadingStatus(prev => ({ ...prev, video4: 'loading' }));
        const fourthVideoPath = await fetchNextVideo(thirdVideoPath.split('/').pop().split('.')[0]);
        if (!fourthVideoPath) {
          console.error('[Init] Failed to fetch fourth video path');
          throw new Error('Failed to fetch fourth video');
        }
        //console.log('[Init] Fourth video path received:', fourthVideoPath);
        await preloadVideo(fourthVideoPath);
        //console.log('[Init] Fourth video preloaded successfully');
        setLoadingStatus(prev => ({ ...prev, video4: 'complete' }));


        // Initialize the queue
        const initialQueue = [firstVideoPath, secondVideoPath, thirdVideoPath, fourthVideoPath];
        setVideoQueue(initialQueue);
        
        // Start with empty history - videos will be added as they're played
        recentVideosRef.current = [];

        setVideos({
          current: firstVideoPath,
          next: secondVideoPath
        });

        //console.log('[Init] Initial loading complete, starting playback');
        setIsFirstVideoReady(true);
        setIsLoading(false);

      } catch (error) {
        console.error('[Init] Error during initialization:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    initializeVideoQueue();
  }, [idToken]);

  const handleVideoTimeUpdate = async (e) => {
    if (isCrossFading || !getCurrentVideoRef().current) return;

    const video = getCurrentVideoRef().current;
    const timeRemaining = video.duration - video.currentTime;

    // Request next videos when we're 10% through the current one
    if (!hasRequestedNext && video.currentTime > video.duration * 0.1) {
      // Check if we're already fetching
      if (isRequestInProgress.current) {
        //console.log('[Queue] Request already in progress, skipping');
        return;
      }

      try {
        const currentQueueSize = videoQueue.length;

        // Only proceed if we're below the target size
        if (currentQueueSize < QUEUE_SIZE) {
          //console.log(`[Queue] Current size: ${currentQueueSize}, Target: ${QUEUE_SIZE}`);

          // Set lock before starting fetch
          isRequestInProgress.current = true;

          const lastVideoPath = videoQueue[videoQueue.length - 1];
          if (lastVideoPath) {
            const lastVideoName = lastVideoPath.split('/').pop().split('.')[0];
            //console.log(`[Queue] Fetching next video after: ${lastVideoName}`);

            const nextVideoPath = await fetchNextVideo(lastVideoName);

            if (nextVideoPath && !isVideoInQueue(nextVideoPath, videoQueue)) {
              await preloadVideo(nextVideoPath);

              // Check queue size again before adding
              setVideoQueue(prev => {
                if (prev.length >= QUEUE_SIZE) {
                  //console.log('[Queue] Queue already full, skipping add');
                  return prev;
                }
                const newQueue = [...prev, nextVideoPath];
                queueSizeRef.current = newQueue.length;
                //console.log(`[Queue] Added video. New size: ${newQueue.length}`);
                return newQueue;
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching next video for queue:', error);
      } finally {
        // Release lock and mark as requested
        isRequestInProgress.current = false;
        setHasRequestedNext(true);
      }
    }

    if (timeRemaining <= (CROSSFADE_DURATION + SAFETY_MARGIN) &&
      videos.next &&
      !isCrossFading &&
      getNextVideoRef().current?.readyState >= 3) {
      startCrossFade();
    }
  };


  //Log queue changes
  /*
  useEffect(() => {
    console.log('[Queue] Queue status:', {
      length: videoQueue.length,
      videos: videoQueue.map(v => v.split('/').pop())
    });
  }, [videoQueue]);
  */

  const startCrossFade = async () => {
    if (isCrossFading || !videos.next) return;

    const currentVideo = getCurrentVideoRef().current;
    const nextVideo = getNextVideoRef().current;

    if (!currentVideo || !nextVideo) return;

    setIsCrossFading(true);

    try {
      nextVideo.currentTime = 0;
      nextVideo.style.opacity = '0';
      await nextVideo.play();

      const timeRemaining = currentVideo.duration - currentVideo.currentTime;
      const actualCrossfadeDuration = Math.min(CROSSFADE_DURATION, timeRemaining - SAFETY_MARGIN);

      requestAnimationFrame(() => {
        currentVideo.style.transition = `opacity ${actualCrossfadeDuration}s linear`;
        nextVideo.style.transition = `opacity ${actualCrossfadeDuration}s linear`;

        currentVideo.style.opacity = '0';
        nextVideo.style.opacity = '1';

        setTimeout(() => {
          finishVideoTransition();
        }, actualCrossfadeDuration * 1000);
      });
    } catch (error) {
      console.error('Error during crossfade:', error);
      setIsCrossFading(false);
    }
  };

  const finishVideoTransition = () => {
    setIsAActive(!isAActive);

    setVideoQueue(prev => {
      if (prev.length > 0) {
        // Add the completed video to recent videos list
        updateRecentVideos(prev[0]);
      }

      const newQueue = prev.slice(1);
      queueSizeRef.current = newQueue.length;
      return newQueue;
    });

    setCurrentVideoIndex(prev => prev + 1);

    setVideos(prev => ({
      current: prev.next,
      next: videoQueue[2]
    }));

    setIsCrossFading(false);
    setHasRequestedNext(false);
  };

  const getLoadingMessage = () => {
    const videoCount = Object.values(loadingStatus).filter(status => status === 'complete').length;
    const message = `Loading videos (${videoCount}/4 complete)...`;
    if (loadingStatus.video1 === 'loading') return `${message} Preparing first video...`;
    if (loadingStatus.video1 === 'complete' && loadingStatus.video2 === 'loading') return `${message} Loading second video...`;
    if (loadingStatus.video2 === 'complete' && loadingStatus.video3 === 'loading') return `${message} Loading additional videos...`;
    return `${message} Preparing video player...`;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 bg-black">
        {!isFirstVideoReady ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              backgroundColor: 'black',
            }}
          >
            <CircularProgress color="primary" sx={{ opacity: 0.8 }} />
            <Typography variant="h6" color="primary" sx={{ mt: 2, opacity: 0.8 }}>
              {getLoadingMessage()}
            </Typography>
            {error && (
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                Error: {error}
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <style>
              {`
                .video-wrapper {
                  position: absolute;
                  inset: 0;
                  background-color: black;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .video-element {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              `}
            </style>

            <div className="video-wrapper">
              <video
                ref={videoA}
                key="video-a"
                src={isAActive ? videos.current : videos.next}
                className="video-element"
                style={{
                  opacity: isAActive ? 1 : 0,
                  zIndex: isAActive ? 1 : 2
                }}
                autoPlay={isAActive}
                playsInline
                muted
                onTimeUpdate={isAActive ? handleVideoTimeUpdate : undefined}
              />

              <video
                ref={videoB}
                key="video-b"
                src={!isAActive ? videos.current : videos.next}
                className="video-element"
                style={{
                  opacity: !isAActive ? 1 : 0,
                  zIndex: !isAActive ? 1 : 2
                }}
                autoPlay={!isAActive}
                playsInline
                muted
                onTimeUpdate={!isAActive ? handleVideoTimeUpdate : undefined}
              />
            </div>

            <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white p-4 text-xs z-10">
              <div>Current: {videos.current?.split('/').pop()}</div>
              <div>Next: {videos.next?.split('/').pop()}</div>
              <div>Queue Size: {videoQueue.length}</div>
              <div>Loading Status: {JSON.stringify(loadingStatus)}</div>
              <div>Active: {isAActive ? 'A' : 'B'}</div>
              <div>Crossfading: {isCrossFading ? 'Yes' : 'No'}</div>
              <div>Queue History ({videoQueue.length}/{MAX_PLAYED_VIDEOS}):
                {videoQueue.map((video, i) => (
                  <div key={i} className="ml-2 text-gray-400">
                    {video.split('/').pop()}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default VideoPlayer;