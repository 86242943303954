import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '../SignIn';
import VideoPlayer from '../App/VideoPlayer'; // Import your VideoPlayer component

const PrivateRoute = ({ authenticated }) => {
  return (
    <Routes>
      {/* Redirect based on authentication */}
      <Route
        path="/"
        element={authenticated ? <VideoPlayer /> : <SignIn />}
      />

      {/* Add a fallback route if needed */}
      <Route
        path="*"
        element={<Navigate replace to={authenticated ? "/" : "/SignIn"} />}
      />
    </Routes>
  );
};

export default PrivateRoute;
