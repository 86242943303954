// App.js
import React, {useState, useEffect, useContext} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import PrivateRoute from '../Navigation/PrivateRoute';
import {FirebaseContext} from '../Firebase';

const App = () => {
  const [authUser, setAuthUser] = useState(null);
  const firebase = useContext(FirebaseContext); // Use Firebase context

  useEffect(() => {
    if (firebase) {
      // Check if firebase is not null
      const listener = firebase.auth.onAuthStateChanged(user => {
        if (user) {
          setAuthUser(user);
        } else {
          setAuthUser(null);
        }
      });

      // Cleanup function
      return () => listener();
    }
  }, [firebase]);

  // Determine authentication status based on authUser's presence
  const authenticated = !!authUser;

  return (

      <Router>
        <div>
          <PrivateRoute authenticated={authenticated} authUser={authUser} />
        </div>
      </Router>

  );
};

export default App;