// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import './index.css'; // Import global styles

const container = document.getElementById('root');
const root = createRoot(container);

// Initialize Firebase instance outside of render
const firebase = new Firebase();

root.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebase}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>
);