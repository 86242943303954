import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBhdIDYk40V8_t2-W_Kfk3AvxFJWoVjV8Y",
  authDomain: "lyricjam-private.firebaseapp.com",
  databaseURL: "https://lyricjam-private-default-rtdb.firebaseio.com",
  projectId: "lyricjam-private",
  storageBucket: "lyricjam-private.appspot.com",
  messagingSenderId: "774193882203",
  appId: "1:774193882203:web:9abed28fcd6a5ed4718b7e",
  measurementId: "G-660GTD1HVE"
};

class Firebase {
  constructor() {
    const app = initializeApp(firebaseConfig);
    this.auth = getAuth(app);
  }

  // Auth API
  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doPasswordReset = email => sendPasswordResetEmail(this.auth, email);

  doSignOut = () => signOut(this.auth);

  doSendEmailVerification = () => {
    const actionCodeSettings = {
      url: "https://ov-research-7.uwaterloo.ca",
      handleCodeInApp: true
    };
    return sendEmailVerification(this.auth.currentUser, actionCodeSettings);
  };


  doPasswordUpdate = password =>
    updatePassword(this.auth.currentUser, password);

  doGetIdToken = (bool) => {
    return this.auth.currentUser?.getIdToken(/* forceRefresh */ bool);
  }

  // Note: getUserByEmail is an Admin SDK method and won't work in client-side code
  // If you need this functionality, you'll need to implement it through a backend service
  doGetUserByEmail = email => {
    console.warn('getUserByEmail is only available in Admin SDK');
    return null;
  }

  doCheckIfEmailIsVerified = (origin) => {
    let verified = true;
    if (origin === "signInWithEmail") {
      if (this.auth.currentUser && this.auth.currentUser.emailVerified === false) {
        verified = true; // change to false if want to verify email
      } else if (this.auth.currentUser && this.auth.currentUser.emailVerified) {
        verified = true;
      }
    } else if (origin === "signUp") {
      verified = true;
    }
    return verified;
  };

}

export default Firebase;