import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../Firebase';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
  ThemeProvider,
  createTheme,
  Box
} from '@mui/material';
import './index.css';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const opacityValue = 0.84;

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#ff752b",
    },
    secondary: {
      main: "#277c5c",
    },
  },
});

const SignIn = () => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  
  const [formState, setFormState] = useState({
    ...INITIAL_STATE,
    browserType: '',
    error: false,
    passwordResetEmailSent: false,
    errorPasswordResetEmailSent: false,
    mobileDevice: false,
  });

  useEffect(() => {
    if (navigator.userAgent.indexOf("Mobi") !== -1) {
      setFormState(prev => ({ ...prev, mobileDevice: true }));
    }
    detectBrowser();
  }, []);

  const detectBrowser = () => {
    let browserType = 'unknown';
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      browserType = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      browserType = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
      browserType = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      browserType = 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
      browserType = 'IE';
    }
    setFormState(prev => ({ ...prev, browserType }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formState;

    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      setFormState({ ...INITIAL_STATE });
      navigate('/', { state: { cameFrom: "signInWithEmail" } });
    } catch (error) {
      setFormState(prev => ({ ...prev, error: true }));
    }
  };

  const onPasswordReset = async () => {
    const { email } = formState;

    try {
      await firebase.doPasswordReset(email);
      setFormState(prev => ({
        ...prev,
        error: false,
        passwordResetEmailSent: true
      }));
    } catch (error) {
      setFormState(prev => ({
        ...prev,
        error: false,
        errorPasswordResetEmailSent: true
      }));
    }
  };

  const onChange = event => {
    setFormState(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const { email, password, error, browserType, mobileDevice } = formState;
  const isInvalid = password === '' || email === '';

  const styleDefinition = browserType === 'Safari' ? "displaySafari" 
                       : browserType === "IE" ? "displayIE" 
                       : "display";

  return (
    <ThemeProvider theme={theme}>
      <Box className={styleDefinition}>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item>
            <Container maxWidth="xs">
              <Box
                component="form"
                noValidate 
                onSubmit={onSubmit}
                sx={{
                  width: '100%',
                  mt: 0,
                  backgroundColor: 'common.darkgrey',
                  opacity: opacityValue,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  opacity: opacityValue,
                }}>
                  <Typography 
                    component="h1" 
                    variant={mobileDevice ? "h2" : "h1"} 
                    color="inherit"
                  >
                    LyricJam
                  </Typography>
                </Box>
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  value={email}
                  onChange={onChange}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputLabelProps={{ shrink: true }}
                  sx={{ opacity: opacityValue }}
                />
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  value={password}
                  onChange={onChange}
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputLabelProps={{ shrink: true }}
                  sx={{ opacity: opacityValue }}
                />
                
                {error && (
                  <Typography color="error">
                    Wrong userID or password.
                  </Typography>
                )}
                
                <Typography>
                  <Link
                    variant="body1"
                    component="button"
                    sx={{ cursor: 'pointer' }}
                    onClick={onPasswordReset}
                  >
                    Forgot password?
                  </Link>
                </Typography>

                {formState.passwordResetEmailSent && (
                  <Typography>
                    Password reset link sent to <b>{email}</b>
                  </Typography>
                )}

                {formState.errorPasswordResetEmailSent && (
                  <Typography color="error">
                    Account with this email address does not exist.
                  </Typography>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ 
                    opacity: opacityValue,
                    my: 3,
                  }}
                >
                  Sign In
                </Button>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default SignIn;